import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { ThemingService } from './theming.service';
import { DatePipe } from '@angular/common';
import { EMPLOYMENT_TYPE } from './constants/employment-type.constant';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, DatePipe, TranslocoPipe],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  themingService = inject(ThemingService);
  translocoService = inject(TranslocoService);

  ngOnInit(): void {
    this.translocoService.setActiveLang(
      navigator.language.split('-')[0] ?? 'en'
    );
  }

  getEmploymentTypeLabel(employmentTypeId: number) {
    return Object.values(EMPLOYMENT_TYPE).find((employmentType) => employmentType.value === employmentTypeId)?.label;
  }
}
