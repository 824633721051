import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { emailRegex, phoneRegex } from '../../../assets/regex';
import { ThemingService } from '../../theming.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
  selector: 'app-main-view',
  standalone: true,
  imports: [ReactiveFormsModule, TranslocoPipe],
  templateUrl: './main-view.component.html',
  styleUrl: './main-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainViewComponent implements OnInit {
  fb: FormBuilder = inject(FormBuilder);
  route = inject(ActivatedRoute);
  router = inject(Router);
  themingService = inject(ThemingService);
  httpClient = inject(HttpClient);

  shownView: WritableSignal<'first' | 'second'> = signal('first');
  termsAccepted = signal(false);

  jobId!: number;
  utmSource!: string;
  utmContent!: string;
  utmMedium!: string;

  loading = signal(false);

  form = this.fb.group({
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern(emailRegex)]],
    phone: ['', [Validators.required, Validators.pattern(phoneRegex)]],
    applyReason: [''],
  });

  ngOnInit(): void {
    const jobId = +this.route.snapshot.params['jobId'];
    this.utmSource = this.route.snapshot.queryParams['utm_source'] ?? '';
    this.utmContent =
      this.route.snapshot.queryParams['utm_source_content'] ?? '';
    this.utmMedium = this.route.snapshot.queryParams['utm_medium'] ?? '';

    if (isNaN(jobId) || jobId === 0) {
      this.router.navigate(['invalid-link']);
    } else {
      this.jobId = jobId;
      this.themingService.loadDesign(jobId);
    }
  }

  next() {
    if (this.form.valid) {
      this.shownView.set('second');
    }
  }

  submit() {
    if (this.form.valid) {
      const payload = {
        ...this.form.value,
        utmSource: this.utmSource,
        utmContent: this.utmContent,
        utmMedium: this.utmMedium,
      };

      this.loading.set(true);

      this.httpClient
        .post(
          `${environment.apiUrl}/leads/jobs/${this.jobId}/create-lead`,
          payload
        )
        .subscribe({
          next: () => {
            this.loading.set(false);
            this.router.navigate(['success']);
            this.form.reset();
          },
          error: () => {
            this.loading.set(false);
            alert('An error occurred. Please try again.');
          },
        });
    }
  }
}
