import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { TranslocoPipe } from '@jsverse/transloco';
import { ThemingService } from '../../theming.service';

@Component({
  selector: 'app-ad-view',
  standalone: true,
  imports: [TranslocoPipe, RouterLink],
  templateUrl: './ad-view.component.html',
  styleUrl: './ad-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdViewComponent implements OnInit {
  route = inject(ActivatedRoute);
  router = inject(Router);
  themingService = inject(ThemingService);

  jobId!: number;

  ngOnInit(): void {
    const jobId = +this.route.snapshot.params['jobId'];

    if (isNaN(jobId) || jobId === 0) {
      this.router.navigate(['invalid-link']);
    } else {
      this.jobId = jobId;
      this.themingService.loadDesign(jobId);
    }
  }
}
