<main>
  <div class="view-wrapper">
    <img [src]="themingService.design()?.logoURL" alt="logo" loading="lazy" cypress-id="company-logo" />
    <h3>{{ themingService.design()?.jobTitle }}</h3>
    <div class="icons">
      @if(themingService.design()?.workLocations?.length) {
      <span>
        📍 {{ themingService.design()?.workLocations?.join(', ') }}
      </span>
      }
      @if(themingService.design()?.employmentTypeId; as employmentTypeId) {
      <span>
        💼 {{ getEmploymentTypeLabel(employmentTypeId) | transloco }}
      </span>
      }
      @if(!!themingService.design()?.startDate) {
      <span>
        @if(themingService.design()?.startDate !== '1970-01-01') {
        🗓️ {{ themingService.design()?.startDate | date: 'dd.MM.yyyy' }}
        } @else {
        🗓️ {{ 'constants.start-date.now' | transloco }}
        }
      </span>
      }
    </div>
    <div class="router-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
</main>