<div class="ad-wrapper">
  <div class="row">
  </div>
  <div class="row g-6">
    <div class="col-12 col-md-6 ad-image-wrapper">
      <div>
        <img [src]="themingService.design()?.imageURL" alt="ad-image" loading="lazy" cypress-id="ad-image" />
      </div>
    </div>
    <div class="col-12 col-md-6 ad-copy-wrapper" [innerHTML]="themingService.design()?.description"></div>
  </div>
</div>
<div class="action-wrapper">
  <button class="ct-btn ct-btn-themed-solid" cypress-id="apply-button" [routerLink]="['/form', jobId]">{{ 'ad-view.apply-now' | transloco }}</button>
</div>