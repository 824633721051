export const EMPLOYMENT_TYPE = {
  PERMANENT: {
    label: 'constants.employment-type.permanent',
    value: 1,
  },
  LIMITED: {
    label: 'constants.employment-type.limited',
    value: 2,
  },
  SCHOOLING: {
    label: 'constants.employment-type.schooling',
    value: 3,
  },
  DUAL_STUDY: {
    label: 'constants.employment-type.dual-study',
    value: 4,
  },
  INTERNSHIP: {
    label: 'constants.employment-type.internship',
    value: 5,
  },
  WORKING_STUDENT: {
    label: 'constants.employment-type.working-student',
    value: 6,
  },
  TRAINEE: {
    label: 'constants.employment-type.trainee',
    value: 7,
  },
  TEMPORARY: {
    label: 'constants.employment-type.temporary',
    value: 8,
  },
  FREELANCER: {
    label: 'constants.employment-type.freelancer',
    value: 9,
  },
  FEE_WORK: {
    label: 'constants.employment-type.fee-work',
    value: 10,
  },
  VOLUNTEERING: {
    label: 'constants.employment-type.volunteering',
    value: 11,
  },
  THESIS: {
    label: 'constants.employment-type.thesis',
    value: 12,
  },
  BY_ARRANGEMENT: {
    label: 'constants.employment-type.by-arrangement',
    value: 13,
  },
};
